import "../Login/Login.css";
import logo from "../../img/logo-ha-horizontal.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import ModalMessage from "../ModalMessage";
import cookieManager from "../CookieManager/CookieManager";


function Login({ logar }) {

    const navigation = useNavigate();

    const [cracha, setCracha] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
    const [nome_empresa, setNomeEmpresa] = useState("Hospital de Amor");

    const apiUrl = process.env.REACT_APP_API_QR_DOMAIN;
    const token = process.env.REACT_APP_API_TOKEN;


    async function loginUser() {

        if (!cracha || !password) {

            toast.dismiss();
            toast.error("Preencha todos os campos!");

            return;

        }

        setIsLoading(true);
        setErrorModalIsOpen(true);

        const config = {
            method: "post",
            url: `${apiUrl}/portal/login`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            data: {
                "nome_empresa": nome_empresa,
                "usuario": cracha,
                "senha": password,
                "portal": true
            },
            withCredentials: true
        };

        try {

            const retorno = await axios(config);

            const response = retorno.data;

            if (response.status === true && response.permissoes.includes("portal")) {

                cookieManager.setCookie("session", response.session_id, 1);

                cookieManager.setCookie("data", JSON.stringify({
                    "name": response.name,
                    "cracha": cracha,
                    "nome_empresa": nome_empresa,
                    "permissoes": response.permissoes
                }), 1);

                logar(true);
                navigation("/home");

            } else if (response.status === true && !response.permissoes.includes("portal")) {

                toast.dismiss();
                toast.error("Usuário não tem permissão para acessar o portal!");

            }

            if (response.code) {

                cookieManager.clearCookies();

                toast.dismiss();
                toast.error("Usuário ou senha inválidos!");

            }

        } catch (error) {

            toast.dismiss();
            toast.error("Sistema em manutenção, tente novamente mais tarde!");

        }

        finally {

            setIsLoading(false);
            setErrorModalIsOpen(false);

        }


    }


    const setandoCracha = (event) => {

        try {

            const inputValue = event.target.value;
            const numericValue = inputValue.replace(/\D/g, "").substring(0, 8); // Remove todos os caracteres que não são números        

            setCracha(numericValue ? parseInt(numericValue, 10) : "");

        } catch (ex) {

            setCracha("");
        }

    };


    const setandoSenha = (event) => {

        try {

            setPassword(event.target.value.substring(0, 32)); // Atualiza o estado com o valor do input

        } catch (ex) {

            setPassword("");
        }

    };


    function crachaOnInput(event) {

        event.target.value = event.target.value.replace(/\D/g, "");

        if (event.target.value !== "") {

            let value = parseInt(event.target.value, 10);

            if (value < 1) {

                event.target.value = 1;
                setCracha(1);

            } else if (value > 99999999) {

                event.target.value = 99999999;
                setCracha(99999999);

            } else {

                event.target.value = value;

            }

        }

    }


    return (

        <div className="container">

            <section className="container-img">
                <div className="bemvindo">
                    <p>Portal de Gerenciamento</p>
                    <h1>HA Colaborador</h1>
                </div>
            </section>

            <section className="container-login">
                <img src={logo} alt="Logo hospital de amor" width="175px" />
                <div className="container-titulo">
                    <h1 className="titulo">Bem-vindo!</h1>
                    <p className="sub-titulo">Preencha os dados para realizar o login.</p>
                </div>

                <div className="secao-input">
                    <input
                        value={cracha}
                        maxLength={8}
                        onChange={setandoCracha}
                        onInput={crachaOnInput}
                        type="text"
                        placeholder="Cracha" />

                    <input value={password}
                        maxLength={32}
                        onChange={setandoSenha}
                        type="password"
                        placeholder="Senha"
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                loginUser()
                        }}
                    />
                </div>

                <button onClick={loginUser} className="btAcessar">
                    <FontAwesomeIcon className="icone" icon={faArrowRight} beatFade />Acessar
                </button>

            </section>

            <ModalMessage isOpen={errorModalIsOpen} isLoading={isLoading} />

        </div>

    );

}


export default Login;